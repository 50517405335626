.swiper-button-next {
  color: rgb(0,255,0);
}

.swiper-button-prev {
  color: rgb(0,255,0);
  left: 17px;
  right: 17px;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.container-1 .swiper-pagination-bullet{
  display: none;
  background-color: rgb(0,255,0);
}

.container-1 .img-description {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 13px;
  position: absolute;
  top: 87%;
  left: 50%;
  background-color: rgb(0,255,0);
  padding: 0 7px;
  transform: translate(-50%, -50%);
  color: rgb(0,0,0);
}

.container-1 .swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.container-1 .swiper-slide {
  text-align: center;
  background: #fff;
  cursor:grab;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.container-1 .swiper-slide img {
  background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  background-position: center 0;
  background-repeat: no-repeat !important;
  display: block!important;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .5s;
  transform: scale(1.1);
}

.container-1 .swiper-slide img:hover{
  transition: all .5s;
  transform: scale(1);
}


.container-2 .swiper-pagination-bullet{
  background-color: rgb(0,255,0);
}

.container-2 .swiper-fixed-height {
  height: 270px;
}


.container-2 .img-profile{
  width: 100%;
  height: 100%;
}

.container-2 .swiper {
  width: 100%;
  height: 100%;
  background: #000;
}

.container-2 .swiper-slide {
  font-size: 18px;
  color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
  cursor:grab;
}

.container-2 .parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.container-2 .swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
  color: rgb(0,0,0);
  font-family: Helvetica, Sans-Serif;
}

.container-2 .swiper-slide .subtitle {
  font-size: 17px;
  color: rgb(0,0,0);
  font-family: Helvetica, Sans-Serif;
}

.container-2 .swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
  color: rgb(0,0,0);
  font-family: Helvetica, Sans-Serif;
}


.videoWrapper {
  position: relative;
  width: 100%;
  height: 0;
  background-color: #000;
  &43 {
    padding-top: 5%;
  }
  &169 {
    padding-top: 5%;
  }
}

.videoIframe {
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.videoPoster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
  border: 0;
  outline: none;
  background-position: 50% 50%;
  background-size: 100% 100%;
  background-size: cover;
  text-indent: -999em;
  overflow: hidden;
  opacity: 1;
  -webkit-transition: opacity 800ms, height 0s;
  -moz-transition: opacity 800ms, height 0s;
  transition: opacity 800ms, height 0s;
  -webkit-transition-delay: 0s, 0s;
  -moz-transition-delay: 0s, 0s;
  transition-delay: 0s, 0s;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80px;
    height: 80px;
    margin: -40px 0 0 -40px;
    border: 5px solid $color-button;
    border-radius: 100%;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    margin: -20px 0 0 -10px;
    border-left: 40px solid $color-button;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    -webkit-transition: border-color 300ms;
    -moz-transition: border-color 300ms;
    transition: border-color 300ms;
  }
  &:hover,
  &:focus {
    &:before {
      border-color: $color-button-hover;
    }
    &:after {
      border-left-color: $color-button-hover;
    }
  }
  .videoWrapperActive & {
    opacity: 0;
    height: 0;
    -webkit-transition-delay: 0s, 800ms;
    -moz-transition-delay: 0s, 800ms;
    transition-delay: 0s, 800ms;
  }
}

.swiper-lazy{
  opacity: 0;
  transition: opacity 700ms ease-in-out;
}

.swiper-lazy-loaded {
  opacity: 1 !important;
}

.video-playicon{
  width:53px;
  height:53px;
}

.-video-box{
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0);
  padding: 0px;
  border-radius: 7px;
  border-color: rgb(177,177,177);
  border-style: solid;
  border-width: 3px;
  overflow: hidden;
}


.swiper-height-job{
    height: 75vh;
}

.swiper-height-edu{
    height: 30vh;
}

.swiper-height-nvidia{
    height: 30vh;
}

.swiper-height-oracle{
    height: 43vh;
}

.swiper-height-hobby{
    height: 63vh;
}





:root{
  --pad-all: 0em;
  --margin-all: 0em;

  --pading-left: 0em;
  --pading-right: 0em;
  --pading-top: 0em;
  --pading-bottom: 0em;
  --margin-left: 2em;
  --margin-right: 2em;
  --margin-top: 2em;
  --margin-bottom: 0em;
}

.swiper-button-next {
  color: rgb(0,255,0);
}

.swiper-button-prev {
  color: rgb(0,255,0);
  left: 17px;
  right: 17px;
}


/*Phone & Max Layout*/

@media screen and (min-width: 769px){
  .biointromediawrapper-style-phone{
    margin: 3em 2em 0 2em;
  }

  .biointrowrapper-style-phone{
    margin: 2em 2em 0 3em;
    padding: 7em 0 0 0;
  }

  .bioengwrapper-style-phone{
    float: left;
    margin-left: 4em;
    padding: 13em 30px 13px 30px;
  }

  .bioengphotojobwrapper-style-phone{
    float: left;
    margin-left: 30px;
    padding: 43px 30px 13px 0px;
    width: 77%;
    height: auto;
  }

  .bioengphotoeduwrapper-style-phone{
    float: left;
    margin-left: 0px;
    padding: 0px 30px 13px 0px;
    width: 83%;
    height: auto;
  }
  .bioartwrapper-style-phone{
    float: left;
    margin: 17px 0px 0px 0px;
    padding: 17px 30px 13px 30px;
  }
  .bioartpromusiccomposerwrapper-style-phone{
    float: left;
    margin-left: 130px;
    padding-top: 60px;
  }
  .bioartprosingwrapper-style-phone{
    float: left;
    margin-left: 130px;
    padding-top: 80px;
  }
  .bioartpromusiclistwrapper-style-phone{
    float: left;
    margin-left: 50px;
    padding: 0px 30px 0px 0px;
    width: 60%;
  }
  .bioartprocctvwrapper-style-phone{
    float: left;
    margin-left: 150px;
    padding-top: 60px;
  }

  .bioartvideocctvwrapper-style-phone{
    float: left;
    margin-left: 50px;
    padding: 60px 100px 0px 0px;
  }
  .biohobwrapper-style-phone{
    float: left;
    margin: 77px 0px 0px 100px;
    padding: 37px 30px 13px 30px;
  }

  .biohobbyvideowrapper-style-phone{
    position: relative;
    padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
    float: left;
    margin-left: 50px;
    padding: 73px 77px 0px 0px;
  }

  .byebyephotowrapper-style-phone{
    float: left;
    margin-left: 0px;
    padding: 60px 30px 63px 63px;
    width: 100%;
    height: 100%;
  }

  .byebyesociallinkswrapper-style-phone{
    float: left;
    margin: 60px 20px 60px 0px;
    padding: 60px 30px 63px 15px;
    width: 100%;
    height: 100%;
  }
}


@media screen and (max-width: 768px){
  .biointromediawrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .biointrowrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);

  }

  .bioengwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .bioengphotojobwrapper-style-phone{
    overflow:hidden;
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .bioengphotoeduwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .bioartwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }
  .bioartpromusiccomposerwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }
  .bioartprosingwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }
  .bioartpromusiclistwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: 1em;
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
    width: 60%;

  }
  .bioartprocctvwrapper-style-phone{
    margin-top: 23em;
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .bioartvideocctvwrapper-style-phone{
    float: left;
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }
  .biohobwrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .biohobbyvideowrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }


  .byebyephotowrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: var(--margin-bottom);
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }

  .byebyesociallinkswrapper-style-phone{
    margin-top: var(--margin-top);
    margin-left: var(--margin-left);
    margin-bottom: 5em;
    margin-right: var(--margin-right);

    padding-top: var(--padding-top);
    padding-left: var(--padding-left);
    padding-bottom: var(--padding-bottom);
    padding-right: var(--padding-right);
  }


}


body {
  background: #eee;

}
