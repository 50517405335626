
:root {
  /* main (page-wide) variables */
  --font-size-var: 0.9rem;
  --font-size-hover-var:1.3rem;
}



.hover-on-image{
  text-align: center;
  top: 3px;
  left:50%;
  width: 37px;
  height: 37px;
  transform: scale(1.0);
}

.hover-on-image:hover{
    transform: scale(1.3);
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
}

.collapsing {
    -webkit-transition: none;
    transition: none;
}
.navbar-toggler {
    border: 0px solid rgba(0, 0, 0, 0);
}

.navitem-width-1{
  font-size: var(--font-size-var);
  width : 20%;
  text-align:center;
  display: inline-block;
}

.navitem-width-2{
  font-size: var(--font-size-var);
  width : 20%;
  text-align:center;

  $.border-style{
  background-image: url("") !important;
  border-left: 2px solid rgba(0, 0, 0, .7);
  border-right: 2px solid rgba(0, 0, 0, .7);
  }
}

.navitem-width-3 {
  width : 20%;
  text-align:center;
}

.navitem-width-4{
  font-size: var(--font-size-var);
  width : 20%;
  text-align:center;
}

.navitem-width-5{
  font-size: var(--font-size-var);
  width : 20%;
  text-align:center;
}

.navitem-width-6{
  font-size: var(--font-size-var);
  width : 20%;
  text-align:center;
  display: none;
}

@media screen and (min-width: 1000px) {
  .link-engineer-style{
    color: black;
    text-decoration: none !important;
  }

  .link-engineer-style:hover{
    color: black;
    font-size:var(--font-size-hover-var);
  }

  .link-multiartist-style{
    color: black;
    text-decoration: none !important;
  }

  .link-multiartist-style:hover{
    color: black;
    font-size:var(--font-size-hover-var);
    letter-spacing: -3px;
  }
  .navitem-width-6{
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 999px) {

  .link-engineer-style{
    color: black;
    text-decoration: none !important;
  }

  .link-engineer-style:hover{
    color: black;
    font-size:var(--font-size-hover-var);
    text-decoration: none !important;
  }

  .link-multiartist-style{
    color: black;
    text-decoration: none !important;
  }

  .link-multiartist-style:hover{
    color: black;
    font-size:var(--font-size-hover-var);
    letter-spacing: -3px;
  }

  .navitem-width-6{
    display: inline-block;
    width : 25%;
  }

  .navitem-width-1 {
    width : 25%;
  }

  .navitem-width-2 {
    width : 25%;
  }

  .navitem-width-3 {
    width : 0px;
  }

  .navitem-width-4 {
    width : 25%;
  }

  .link-backhome-style{
    color: black;
    font-size: var(--font-size-var);
    text-align:center;
    text-decoration: none !important;
  }

  .link-backhome-style:hover{
    color: black;
    font-size: var(--font-size-hover-var);
    width : 20%;
    text-align:center;
  }


  .navitem-display-control-style{
       display: none;
       font-size: 0px;
       width: 0px;
       height: 0px;
    }
}


.navbarwrapper1-style{
  display: inline-block;
  width: 100%;
}

.navbarwrapper2-style{
  display: none;
  width: 0px;
}

@media screen and (max-width: 768px){
  .navbarwrapper1-style{
    display: none;
    width: 0px;
  }

  .navbarwrapper2-style{
    display: block;
    width: 100%
  }

  .link-engineer-style{
    color: black;
    text-decoration: none !important;
  }

  .link-engineer-style:hover{
    color: black;
    font-size:var(--font-size-var);
    text-decoration: none !important;
    letter-spacing: 1px;
  }

  .link-multiartist-style{
    color: black;
    text-decoration: none !important;
  }

  .link-multiartist-style:hover{
    color: black;
    font-size:var(--font-size-var);
    letter-spacing: 1px;
  }

  .link-backhome-style{
    color: black;
    text-decoration: none !important;
  }

  .link-backhome-style:hover{
    color: black;
    font-size:var(--font-size-var);
    letter-spacing: 1px;
  }

  .navitem-width-1 {
    text-align:center;
    width: 100%;
  }

  .navitem-width-2{
    overflow: hidden;
    font-size: var(--font-size-var);
    text-align:center;
    width: 100%;

    $.border-style{
    background-image: url("") !important;
    border-left: 2px solid rgba(0, 0, 0, .7);
    border-right: 2px solid rgba(0, 0, 0, .7);
    }
  }


  .navitem-width-4{
    display: inline-block;
    font-size: var(--font-size-var);
    text-align:center;
    width: 100%;
  }

  .navitem-width-6{
    display: inline-block;
    font-size: var(--font-size-var);
    text-align:center;
    width: 100%;
  }

  .-navbar-light .navbar-toggler-icon {
    background-image:url('../../statics/logoGreen1.jpg');
  }

}
